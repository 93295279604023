
import {request} from "@/network/request.js";
import qs from 'qs';

export default{
  // 查询-条件查询销售业绩汇总信息
  findAchievementsReport(data){
    return request({
      method:'POST',
      url:'/report/achievements/findAchievementsReport',
      data
    })
  },
  //查询-条件查询销售业绩统计列表信息
  findAchievementsReportList(data){
    return request({
      method:'POST',
      url:'/report/achievements/findAchievementsReportList',
      data
    })
  }
}